import React from 'react'
import {BrowserRouter} from "react-router-dom";
import BaseRoutes from "./routes/BaseRoutes";
import ScrollToTop from "./routes/Scroll";
import RouteChangeTracker from "./routes/RouteChangeTracker";
//import ReactGA from 'react-ga';
import ReactGA from 'react-ga4';


const App = () => {
  React.useEffect(() => {
    const cookieAccepted = () => {
      const name = "cookies=";
      const cDecoded = decodeURIComponent(document.cookie);
      const cArr = cDecoded.split('; ');
      let res;
      cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
      })
      return res
    }
    
    const TRACKING_ID = process.env.REACT_APP_TRACKING_ID_GA4;
    if(cookieAccepted() == 'true')
    {
      
      ReactGA.initialize(TRACKING_ID);
    }
    else
    {
      ReactGA.initialize(TRACKING_ID, {
        GaOptions: {
            anonymizeIp: true
        }
      });
    }
    // this piece of code checks whether a user has previously agreed to cookie policy.
    // if they have, we simply initialize reactGA.
    //if they haven't well, it means that no "cookies" was found in localstorage. reactGA will not be initialized until they accept..... yad rkhin khuda da wasta bhulin na
  }, [])

 
  return (
    <>
    <BrowserRouter>
        <RouteChangeTracker/>
        <ScrollToTop/>
        <BaseRoutes />
    </BrowserRouter>
    </>
  )
}

export default App;